<template>
    <PModal header-class="modal-form-header" :footer-actions="modalActions">
        <template #title>Танланг</template>

        <div>
            <b-input ref="searchInput" v-model="search"></b-input>

            <div class="icon-picker-list">
                <div
                    :key="icon"
                    class="icon-preview"
                    :class="{ active: selected === icon }"
                    v-for="icon in filteredIcons"
                    @click="selected = icon"
                    @dblclick="onPicked(icon)"
                >
                    <i :title="icon" :class="icon"></i>
                </div>
            </div>
        </div>
    </PModal>
</template>

<script>
import icons from "@Platon/external/icons"
import ModalCloseOnEscMixin from "@Platon/mixins/ModalCloseOnEscMixin"

export default {
    name: "IconPickerDialog",

    mixins: [ModalCloseOnEscMixin],

    props: {
        onPick: {
            type: Function,
            default: () => () => {}
        }
    },

    data() {
        const iconSet = new Set(icons)

        return {
            icons: Array.from(iconSet),

            search: "",

            selected: undefined
        }
    },

    methods: {
        onPicked(icon) {
            this.$platonApp.greenToast(this.$l("platon.toast_success_selected"), "Танланди")

            this.onPick(icon)
            this.$emit("close")
        }
    },

    computed: {
        modalActions() {
            return [
                {
                    text: "Бекор килиш"
                },
                {
                    text: "Танлаш",
                    handler: () => {
                        this.onPicked(this.selected)
                    },

                    disabled: !this.selected
                }
            ]
        },

        filteredIcons() {
            return this.icons.filter((x) => {
                return x.includes(this.search)
            })
        }
    },
    mounted() {
        this.$refs.searchInput.focus()
    }
}
</script>

<style scoped lang="scss">
.icon-preview {
    width: 50px;
    height: 50px;
    display: inline-flex;
    margin: 0.4rem 0.2rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.active {
        border-radius: 5px;
        background: #e6e6fa;
    }
}

.icon-preview i {
    font-size: 20px;
}

.icon-picker-list {
    height: calc(90vh - 200px);
    overflow: auto;
}
</style>
